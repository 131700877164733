$white: #ffffff;
$black: #000000;
$darkBlue: #00205C;
$lightBlue: #C2E5F6;
$paleBlue: #E7F5FC;
$brandBlue: #009ADE;
$red: #ef3842;
$redError: #D32F2F;
$gray: #aca4a4;
$lightGray: #DEE0E0;
$paleGray: #F9FAFB;
