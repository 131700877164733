@import 'css/colors.scss';

.info {
  background-color: $paleBlue;
}

.success {
  background-color: #EDF7ED;
}

.notification {
  display: flex;
  align-items: center;
  width: 100%;
}