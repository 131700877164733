@import 'css/colors.scss';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  border-radius: 10px;
  overflow: hidden;
  outline: none;
}

.body {
  padding: 24px;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: $darkBlue;
}