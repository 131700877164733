.row {
  margin: 16px 0;
  width: 100%;
}

.noBottomMargin {
  margin-bottom: 0;
}

.noTopMargin {
  margin-top: 0;
}