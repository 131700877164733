.button {
  font-size: 14px;
}

.ghost {
  border-color: #009ADE;
  color: #009ADE;

  &:hover{ 
    border-color: #009ADE;
  }
}

.small {
  padding: 2px 18px;
}

.medium {
  padding: 4px 26px;
}

.large {
  padding: 8px 32px;
} 