@import 'css/colors.scss';

.divider {
  margin: 16px 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba($black, 0.12);
  border-bottom-width: thin;
  margin: 16px 0;
}

.noMargin {
  margin: 0;
}